<template>
  <span>
    <!-- Timetable heading section -->
    <v-row align="center" :class="modal ? onMobile ? 'py-4' : 'px-4 pt-4 pb-2 my-0' : 'py-4'">
      <v-col class="py-2 px-3">
        <v-row align="center">
          <p class="text-h4 mb-0 mr-5">{{ courseHeading ? course : 'Timetable' }}</p>
          <QuickStats v-if="!onMobile && showStats" :stats="courseInfo" icon-size="26"/>
        </v-row>
        <v-row v-if="showStats && !onMobile"><p class="text-body-1 mb-0">{{ courseInfo.name }}</p></v-row>
      </v-col>
      <v-btn v-if="!onMobile && timetableInfo.controls && !showRestrictions" color="warning" :disabled="!hasNotes"
              text class="text-capitalize pa-1" @click="viewNotes = !viewNotes">
        <v-icon class="mr-2" size="20" color="warning">mdi-alert-outline</v-icon>
        {{ hasNotes ? 'View Restrictions/Notes' : 'Restrictions/Notes Exist' }}
      </v-btn>
      <v-divider v-if="!onMobile && timetableInfo.controls && !showRestrictions && filters.semesters.length > 2" vertical class="my-2 mx-4"/>
      <DropMenu v-if="filters.semesters.length > 2" icon="mdi-calendar-clock" :options="filters.semesters"
                :chevron="!onMobile" @optionUpdate="filters.active = $event" :default-active="filters.active"/>
    </v-row>
    <!-- Timetable section -->
    <span v-if="modal && hasNotes && viewNotes" :class="modal && !onMobile ? 'modalCSS' : 'mobilePadding'">
      <v-alert class="timetableRow mt-3 mb-1" border="left" text type="warning" icon="mdi-alert-outline">
        <v-runtime-template v-if="hasNotes" class="mb-0" :template="parseEnrolmentNotes(timetableInfo.notes)"/>
      </v-alert>
    </span>
    <span v-else :class="modal && !onMobile ? 'modalCSS' : 'mobilePadding'">
      <v-row v-if="availableSections.length === 0 && !loading" class="my-8 mx-0">
        <v-col>
          <v-row justify="center"><v-icon color="accent" size="75" class="py-6">mdi-calendar-question</v-icon></v-row>
          <p class="text--secondary text-center">No current offerings available</p>
        </v-col>
      </v-row>
      <!-- Course enrolment control banner -->
      <v-row v-if="timetableInfo.controls && showRestrictions" class="mt-4 px-1" :style="modal ? 'margin-top: 70px;' : ''">
        <v-col class="pa-0">
          <v-alert class="timetableRow mb-1" border="left" text type="warning" icon="mdi-alert-outline">
            <v-row class="my-0 px-3 pointer" @click="viewNotes = !viewNotes">
              <v-col cols="11" class="pa-0">
                <span class="mb-0 text-body-1"><strong>Please note</strong> that this course has enrolment restrictions/notes.
                <span v-if="onDesktop && hasNotes"><strong>Click to toggle the restrictions/notes.</strong></span>
                <span v-if="!onMobile && !hasNotes">View the official
                  <a href="https://ttb.utoronto.ca/" target="_blank" class="hover accent--text">UofT timetable</a> site for more details.
                </span>
              </span>
              </v-col>
              <v-col cols="1" class="pa-0 text-right" align-self="center">
                <v-icon v-if="hasNotes" color="warning">{{ viewNotes ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-col>
            </v-row>
            <v-runtime-template v-if="viewNotes && hasNotes" class="mt-5 mb-0" :template="parseEnrolmentNotes(timetableInfo.notes)"/>
          </v-alert>
        </v-col>
      </v-row>
      <!-- Data tables for lectures, tutorials, and practicals -->
      <v-row v-if="loading" justify="center">
        <v-progress-circular indeterminate size="64" width="6" color="accent" class="py-12 my-12"/>
      </v-row>
      <v-row v-if="!loading && disableAdd" class="justify-center pt-3 px-1">
        <v-alert class="timetableRow font-weight-medium mb-1" border="left" text type="error" icon="mdi-alert-octagon-outline">
          <span>Fall Y-term courses (double semester courses) are currently unsupported.</span>
        </v-alert>
      </v-row>
      <v-row v-for="(section, idx) in availableSections" :key="idx" justify="center"
             :class="'timetableRow' + (((parseInt(idx) === availableSections.length - 1) && timetableInfo[section.label].length <= 10 && !onMobile) ? ' mb-4' : '')">
        <v-col class="px-1 py-3">
          <p class="text-h6 font-weight-regular mb-0">{{ section.value }}</p>
          <v-data-table :headers="tableHeadings.slice(0, section.slice)" class="timetableRow" disable-sort single-select
                        :hide-default-footer="timetableInfo[section.label].length <= 10" :footer-props="footerProps"
                        :items="timetableInfo[section.label]" :hide-default-header="onMobile" item-key="section"
                        :show-select="enableSelection" checkbox-color="accent" v-model="selected[section.label]"
                        :style="backgroundColour" :data-cy="loading ? '' : (section.label + '_section')">
            <!-- Templates for custom CSS on whatever columns -->
            <template v-slot:item.data-table-select="{ isSelected, select, item }">
              <v-row v-if="store.app.onMobile" class="pt-1">
                <v-col cols="1" class="pl-3">
                  <v-simple-checkbox v-if="item.isAvailable" :ripple="!store.app.onMobile" :value="isSelected"
                                     :disabled="disableAdd && item.section.startsWith('F') && item.section.endsWith('Y')"
                                     @input="select($event)" color="accent"/>
                  <v-tooltip v-else bottom max-width="250px" color="tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="warning">mdi-information-outline</v-icon>
                    </template>
                    <span class="font-weight-medium">This section is currently unavailable. Please check back again later.</span>
                  </v-tooltip>
                </v-col>
                <v-col>
                  <CourseOfferingRow :course="item" :section="section"/>
                </v-col>
              </v-row>
              <span v-else>
                <v-simple-checkbox v-if="item.isAvailable" v-ripple :value="isSelected" @input="select($event)" color="accent"
                                   :disabled="disableAdd && item.section.startsWith('F') && item.section.endsWith('Y')"/>
              <v-tooltip v-else bottom max-width="250px" color="tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="warning">mdi-information-outline</v-icon>
                </template>
                <span class="font-weight-medium">This section is currently unavailable. Please check back again later.</span>
              </v-tooltip>
              </span>
            </template>
            <template v-if="onMobile" v-slot:item.section="{ item }">
              <CourseOfferingRow :course="item" :section="section"/>
            </template>
            <template v-else v-slot:item.section="{ item }">
              <h4 :class="item.hasConflicts ? 'warning--text' : 'font-weight-regular'">{{ item.section }}
                <v-chip v-if="item.hasConflicts" small color="warning" class="ml-3 px-2 font-weight-bold">CONFLICT</v-chip>
              </h4>
            </template>
            <template v-slot:item.times.day="{ item }">
              <v-row v-for="(time, idx) in item.times" :key="idx" class="mx-0 my-2">
                <h4 class="font-weight-regular">{{ time.day }}</h4>
                <h4 v-if="onMobile" class="font-weight-medium pl-1"> @ {{ time.time }}</h4>
              </v-row>
            </template>
            <template v-slot:item.times.time="{ item }">
              <v-row v-for="(time, idx) in item.times" :key="idx" class="mx-0 my-2">
                <h4 class="font-weight-regular">{{ time.time }}</h4>
              </v-row>
              <v-row v-if="item.times.length < 1" class="mx-0 my-2">
                <h4 class="font-weight-regular">{{ item.delivery }}</h4>
              </v-row>
            </template>
            <template v-slot:item.delivery="{ item }">
              <v-row v-for="(time, idx) in item.times" :key="idx" class="mx-0 my-2">
                <v-row class="my-0">
                  <v-tooltip bottom max-width="250px" color="tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-if="time.location.map" v-bind="attrs" v-on="on" size="20" color="accent" class="mr-1"
                              @click="viewRoom(time.location.map)">mdi-map-marker</v-icon>
                    </template>
                    <span class="font-weight-medium">
                      View Map
                      <v-icon size="12" color="#FFFFFF">mdi-open-in-new</v-icon>
                    </span>
                  </v-tooltip>
                  <h4 class="font-weight-regular">{{ time.location.room }}</h4>
                </v-row>
              </v-row>
            </template>
            <template v-slot:item.enrolment="{ item }">
              <chip :text="item.enrolment + ' / ' + item.capacity" label enrolment/>
            </template>
            <template v-slot:item.instructor="{ item }">
              <v-row v-if="profLinks">
                <p v-if="!item.instructor.length" class="mb-0">N/A</p>
                <div v-for="(prof, idx) in item.instructor" :key="idx" class="d-flex">
                  <h4 class="hover accent--text text-decoration-underline" @click="viewProfessor(prof)">
                    {{ prof.displayName }}
                  </h4>
                  <span v-if="idx !== item.instructor.length - 1" class="text--text mx-1">/</span>
                </div>
              </v-row>
              <p v-else class="mb-0">{{ item.instructor.map(prof => prof.displayName).join(' / ') || 'N/A' }}</p>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </span>
  </span>
</template>

<script>
import DropMenu from '@/components/DropMenu'
import QuickStats from '@/components/QuickStats'
import VRuntimeTemplate from 'v-runtime-template'
import CourseOfferingRow from '@/components/CourseOfferingRow'

import { mapState } from 'pinia'
import { timetableTimeToDateRange } from '@/utils/shared/timetableDateTime'
import { sectionsOverlap } from '@/utils/CSP/constraints'
import Chip from '@/components/Chip.vue'
import { useAllStores } from '@/stores/useAllStores'

export default {
  name: 'CourseOfferings',
  components: { Chip, DropMenu, QuickStats, VRuntimeTemplate, CourseOfferingRow },
  props: {
    course: { type: String, required: true },
    courseHeading: { type: Boolean, default: false },
    profLinks: { type: Boolean, default: false },
    enableSelection: { type: Boolean, default: false },
    makeSelection: { type: Boolean, default: false },
    reset: { type: Boolean, default: false },
    showStats: { type: Boolean, default: false },
    showRestrictions: { type: Boolean, default: false },
    modal: { type: Boolean, default: false },
    defaultSemester: { type: String, default: '' }, // Semester YYYY
    courseColor: { type: String, default: null }
  },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: (vm) => ({
    loading: true,
    initialized: false,
    disableAdd: false,
    viewNotes: false,
    currentData: { course: null, sem: null, year: null },
    courseInfo: { name: '', bird: 0, drop: 0, rating: 0, numReviews: 0 },
    courseSelectedColor: vm.courseColor,
    timetableInfo: { controls: false, notes: null, lec: [], tut: [], pra: [] },
    selected: { lec: [], tut: [], pra: [] },
    sections: [
      { label: 'lec', value: 'Lectures', slice: 6 },
      { label: 'tut', value: 'Tutorials', slice: 5 },
      { label: 'pra', value: 'Practicals', slice: 5 }
    ],
    mobileHeadings: [],
    sectionHeadings: [
      { text: 'Section', align: 'start', value: 'section' },
      { text: 'Day', value: 'times.day' },
      { text: 'Time', value: 'times.time' },
      { text: 'Location', value: 'delivery' },
      { text: 'Enrolment', value: 'enrolment' },
      { text: 'Instructor(s)', value: 'instructor' }
    ],
    filters: { loaded: false, semesters: [], active: 0 }
  }),
  async mounted () {
    await this.getTimetable(false)
    if (!this.modal) {
      this.$watch('selected', function () {
        if (this.enableAdd || this.selectedSections.length === 0) { this.addCourse() }
      }, { deep: true })
    }
    if (!this.modal) this.viewNotes = !this.store.app.onMobile
  },
  watch: {
    course: function (newValue) { if (newValue) this.getTimetable(false) },
    defaultSemester: function (newValue) { if (newValue) this.getTimetable(false) },
    makeSelection: function (newValue) { if (newValue) this.addCourse() },
    courseColor: function (newValue) { this.courseSelectedColor = newValue },
    reset: function (newValue) {
      if (newValue) this.selected = { lec: [], tut: [], pra: [] }
      this.checkInCart()
    },
    'filters.active': function (newValue, oldValue) { if (oldValue !== newValue) this.getTimetable(true) }
  },
  computed: {
    ...mapState(useAllStores, {
      onMobile: store => store.app.onMobile,
      onDesktop: store => store.app.onDesktop
    }),
    timetableCourses () {
      return this.store.data.timetableCourses
    },
    availableSections () {
      return this.sections.filter(section => this.timetableInfo[section.label].length > 0)
    },
    selectedSections () {
      return Object.values(this.selected).filter(entry => { return entry[0] !== undefined })
    },
    enableAdd () {
      if (this.selectedSections[0]) {
        let result = true
        const semester = this.selectedSections[0][0].section[0]

        this.availableSections.slice(1).forEach((sec) => {
          const sectionSemesters = this.timetableInfo[sec.label].reduce((acc, cur) => acc.add(cur.section[0]), new Set())
          const noSelectionMade = this.selected[sec.label].length < 1
          const sectionSemesterMatches = semester === this.selected[sec.label][0]?.section[0]

          if (sectionSemesters.has(semester) && (noSelectionMade || !sectionSemesterMatches)) { result = false }
        })
        return result
      } else {
        return false
      }
    },
    tableHeadings () {
      if (!this.onMobile) {
        return this.sectionHeadings
      } else {
        return this.sectionHeadings.filter((element, index) => this.mobileHeadings.includes(index))
      }
    },
    footerProps () {
      return { showFirstLastPage: !this.onMobile, itemsPerPageText: !this.onMobile ? 'Sections per page' : '' }
    },
    hasNotes () {
      return this.timetableInfo.notes && this.timetableInfo.notes.length > 0
    },
    backgroundColour () {
      return { '--background-colour': this.modal ? '#1E1E1E' : '#0F0F0F' }
    }
  },
  methods: {
    async getTimetable (updateSemester) {
      const queryVars = { course: this.course, sem: null, year: null }
      // Check for default semester on initial load
      if (this.filters.semesters.length && (!this.filters.loaded || updateSemester)) {
        queryVars.sem = this.filters.semesters[this.filters.active].value.sem
        queryVars.year = this.filters.semesters[this.filters.active].value.year
      } else if (this.defaultSemester.length) {
        queryVars.sem = this.defaultSemester.split(' ')[0][0]
        queryVars.year = this.defaultSemester.split(' ')[1]
      }
      // Prevent pulling new data
      if (JSON.stringify(this.currentData) !== JSON.stringify(queryVars)) {
        this.loading = true
        this.currentData = queryVars
        // Get course stats
        if (this.showStats) await this.getCourseStats()
        // Reset data
        this.timetableInfo = { controls: false, notes: null, lec: [], tut: [], pra: [] }
        this.selected = { lec: [], tut: [], pra: [] }
        if (!this.modal) this.viewNotes = !this.store.app.onMobile
      } else {
        return
      }

      const q = {
        query: 'query getTimetable($course: String!, $sem: String, $year: String) { ' +
          'getTimetable(course: $course, sem: $sem, year: $year) { timetable, filters } }',
        variables: queryVars,
        operationName: 'getTimetable'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            this.timetableInfo = graphQlRes.data.getTimetable.timetable
            this.filters.semesters = graphQlRes.data.getTimetable.filters
            this.filters.active = Math.max(0, this.filters.semesters.findIndex(sem => { return sem.value.sem === queryVars.sem }))
            this.filters.loaded = true
            // Disable Fall-Y until supported
            this.disableAdd = Object.values(this.timetableInfo).flat().slice(4).some((entry) => {
              return entry.section.startsWith('F') && entry.section.endsWith('Y')
            })
            if (this.enableSelection) {
              this.checkInCart()
              this.computeConflicts()
            }
          } else {
            this.currentData = { course: null, sem: null, year: null }
            this.$toast.error(graphQlRes.errors[0].message)
          }
          this.loading = false
        })
        .catch((e) => {
          this.$toast.error('An error occurred when contacting the server. Please try again later.')
        })
    },
    async getCourseStats () {
      // Call backend for course details
      const q = {
        query: 'query courseInfo($course: String!) { courseInfo(course: $course) { name, bird, drop, rating, numReviews } }',
        variables: { course: this.course },
        operationName: 'courseInfo'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            this.courseInfo = graphQlRes.data.courseInfo[0]
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
        })
        .catch(() => this.$toast.error('An error occurred when contacting the server. Please try again later.'))
    },
    computeConflicts () {
      const sectionTypes = ['lec', 'pra', 'tut']

      // Holds the timings for every single section that was already chosen by user
      const allCourseTimes = []

      this.timetableCourses.forEach(course => {
        const { lecture, tutorial, data } = course
        const code = data.courseResult.code
        // If we need more data in the future, we can add course code, etc. pretty easily
        if (lecture) allCourseTimes.push({ ...lecture, code })
        if (tutorial) allCourseTimes.push({ ...tutorial, code })
      })

      // Need to parse and clean up the start/end times for each section
      sectionTypes.forEach(sectionType => {
        this.timetableInfo[sectionType].forEach(section => {
          section.times.forEach(sectionTime => {
            const { start, end } = timetableTimeToDateRange(sectionTime)
            sectionTime.start = start
            sectionTime.end = end
          })
        })
      })

      // Determine if any conflicts
      sectionTypes.forEach(sectionType => {
        this.timetableInfo[sectionType].forEach(section => {
          allCourseTimes.forEach((section2) => {
            const code = this.timetableInfo.code

            const isSameSection = code === section2.code && section.section === section2.section
            if (isSameSection) return

            const isSameSemester = section.section[0] === section2.section[0]

            if (isSameSemester && sectionsOverlap(section, section2)) {
              section.hasConflicts = true
            }
          })
        })
      })
    },
    checkInCart () {
      const timeIndex = this.timetableCourses.findIndex((entry) => { return entry.data.courseResult.code === this.course })
      if (timeIndex >= 0) {
        const timeEntry = this.timetableCourses[timeIndex]
        const noLecSection = timeEntry.lecture.section.indexOf('LEC') >= 0
        this.selected.lec = noLecSection ? [timeEntry.lecture] : []
        this.selected.tut = noLecSection ? [timeEntry.tutorial] : [timeEntry.lecture]
        this.selected.pra = timeEntry.practical ? [timeEntry.practical] : []
      } else {
        this.initialized = true
      }
    },
    addCourse () {
      // Prevent extra call on initial load if there is already a selected time
      if (!this.initialized && !this.modal) {
        this.initialized = true
        return
      }
      let message = null
      if (this.enableAdd || (!this.modal && this.selectedSections.length < 1)) {
        // Check for existing record first
        const timeIndex = this.timetableCourses.findIndex((entry) => {
          return entry.data.courseResult.code === this.course
        })
        if (timeIndex > -1) {
          message = 'Selection removed!'
          this.store.data.deleteTimetableEntry([this.course, this.timetableCourses[timeIndex].lecture.section[0]])
        }
        if (this.enableAdd && this.selectedSections.length > 0) {
          // Filter out the other semester entries to prevent them from being included in the SHUFFLE
          const timetableData = { ...this.timetableInfo }
          if (this.filters.active === 0 && this.filters.semesters.length > 1) {
            Object.entries(this.selected).forEach(section => {
              if (section[1].length) {
                timetableData[section[0]] = timetableData[section[0]].filter(entry => entry.section[0] === section[1][0].section[0])
              }
            })
          }
          // Create the timetable course entry for the store
          const timetable = {
            ...timetableData,
            _id: this.selected.lec.length ? this.selected.lec[0].timetableId : this.selected.tut[0].timetableId
          }
          const newEntry = {
            data: {
              courseResult: { code: this.course },
              timetableResult: { filters: this.filters.semesters, timetable: timetable }
            },
            isBlockedTime: false,
            lecture: this.selected.lec.length ? this.selected.lec[0] : this.selected.tut[0],
            tutorial: this.selected.lec.length ? this.selected.tut[0] : undefined,
            practical: this.selected.pra[0],
            color: this.courseSelectedColor
          }
          // Update message and save to the store
          message = message ? 'Selection updated!' : (message ? 'Selection removed!' : 'Selection saved!')
          this.store.data.addTimetableEntry(newEntry)
          this.$emit('update', newEntry)
        }
        if (!this.modal) this.$toast.info(message, { timeout: 3000 })
      } else {
        this.$toast.error('You must select one time from each available section and from the same semester!', { timeout: 8000 })
        this.$emit('update', false)
      }
    },
    viewRoom (link) {
      this.$gtag.event('viewed_room_map', { value: 1 })
      window.open(link, '_blank').focus()
    },
    parseEnrolmentNotes (html) {
      const parsedHTML = '<div>' + html + '</div>'
      return parsedHTML.replaceAll(/<p\s*[^<]*>/g, '<p class="mb-0">')
    },
    viewProfessor (prof) {
      this.store.data.setSelectedProf(prof)
      this.$router.push({ path: '/professors', query: { p: prof._id } })
    }
  }
}
</script>

<style scoped>
  >>>a {
    color: var(--v-accent-base) !important;
    font-weight: 500;
  }
  >>>a:hover {
    color: var(--v-primary-base) !important;
    text-decoration: underline;
  }
  .theme--dark.v-data-table {
    background-color: var(--background-colour) !important;
  }
  .v-simple-checkbox { /* Do not remove */
    width: 24px;
  }
  .timetableRow {
    min-width: 100%;
  }
  .modalCSS { /* Do not remove */
    max-height: 450px;
    display: inline-block;
    overflow-y: auto;
    width: 100%;
    padding: 15px;
  }
  .mobilePadding { /* Do not remove */
    padding-bottom: 85px !important;
  }
  >>>.v-btn.v-btn--disabled, .v-btn.v-btn--disabled .v-icon { /* Do not remove */
    opacity: 1;
    color: var(--v-warning-base) !important;
  }
  >>>.v-data-table__mobile-row__cell {
    overflow-x: clip;
    width: 100%;
  }

</style>
